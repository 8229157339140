import { useRef, useState } from "react";
import TagManager from "react-gtm-module";
import { Button } from "../../../components";
import { ExamplesLink } from "../../../components";

export const EditText = ({ nextStep, thanks, setThanks }) => {
  const fileRef = useRef();
  // const [valueInput1, setvalueInput1] = useState('');
  // const [valueInput2, setvalueInput2] = useState('');
  // const [isGetImg, setIsGetImg] = useState(false)

  const handleChange = (e) => {
    if (e.target.id === "text") {
      // setvalueInput1(thanks.text);

      if (e.target.value.length <= thanks.design.maxThanksText) {
        setThanks({ ...thanks, [e.target.id]: e.target.value });
      }
      // document.querySelector(".ac-line-2").innerText = document.querySelector("#text").value;
    }
    if (e.target.id === "to") {
      // setvalueInput2(thanks.text);
      if (e.target.value.length <= thanks.design.maxThanksTo) {
        setThanks({ ...thanks, [e.target.id]: e.target.value });
      }
      // document.querySelector(".ac-line-1").innerText = document.querySelector("#to").value;
    }
    if (e.target.id === "from") {
      if (e.target.value.length <= 15) {
        setThanks({ ...thanks, [e.target.id]: e.target.value });
      }
    }
  };
  const removePicture = () => {
    setThanks({ ...thanks, img: "" });
    // setIsGetImg(false);
  };
  // const getImg = (e) => {
  //   TagManager.dataLayer({dataLayer: {event: 'upload_pic'}})
  //   let reader = new FileReader();
  //   reader.readAsDataURL(e.target.files[0]);
  //   reader.onload = function () {
  //     setThanks({ ...thanks, img: reader.result });
  //   };
  //   reader.onerror = function (error) {
  //     console.log('Error: ', error);
  //   };
  //   setIsGetImg(true);
  // };
  const done_thanks = () => {
    nextStep();
    TagManager.dataLayer({ dataLayer: { event: "go_to_preview" } });
  };
  return (
    <div className="edit-text">
      <div className="ac-chocoladimg-wrapper">
        <img
          src="/img/2022/back.png"
          width="1200px"
          height="1200px"
          alt="milka preview at form"
          className="ac-formilka-img"
        />
        <div className="ac-typedinput">
          <p className="ac-line-1">{thanks.to}</p>
          <p className="ac-line-2">{thanks.text}</p>
        </div>
        <img
          src={thanks.design.srcCub}
          width="1200px"
          height="1200px"
          alt="milka preview at form"
          className="ac-formilka2-img"
        />
      </div>
      <h1>
      your tender<br/><span className="ac-difcolor multiply "> Greeting:</span> 
        <span className="ac-white"></span>
      </h1>
      <form style={{ marginBottom: "1rem" }}>
        <div className="form-group">
          <label htmlFor="to">First line</label>
          <input
            placeholder="First line"
            id="to"
            maxLength={thanks.design.maxThanksTo}
            value={thanks.to}
            onChange={handleChange}
          />
          <span className="ac-limit">{thanks.to.length}/15</span>
        </div>
        {/* <div className="form-group">
          <label htmlFor="from">ממי התודה?</label>
          <input
            placeholder="שורה ראשונה"
            id="from"
            maxLength="15"
            value={thanks.from}
            onChange={handleChange}
          />
          <span className="ac-limit">{thanks.from.length}/15</span>
        </div> */}
        <div className="form-group">
          <label htmlFor="text">Second line</label>
          {/* <textarea
            rows="5"
            maxLength={thanks.design.maxThanksText}
            id="text"
            value={thanks.text}
            onChange={handleChange}
          /> */}
          <input
            placeholder="Second line"
            id="text"
            maxLength={thanks.design.maxThanksText}
            value={thanks.text}
            onChange={handleChange}
          />
          {/* <span>{thanks.text.length}/{thanks.design.maxThanksText}</span> */}
        </div>
        {/* {isGetImg ||thanks.img?
          <div className="ac-wrapper-smile">
              <div className="ac-wrapper-smile-inner"> <svg ariaHidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512" class="ac-smile"><path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm4 72.6c-20.8 25-51.5 39.4-84 39.4s-63.2-14.3-84-39.4c-8.5-10.2-23.7-11.5-33.8-3.1-10.2 8.5-11.5 23.6-3.1 33.8 30 36 74.1 56.6 120.9 56.6s90.9-20.6 120.9-56.6c8.5-10.2 7.1-25.3-3.1-33.8-10.1-8.4-25.3-7.1-33.8 3.1z" class=""></path></svg></div>
          </div>
          : null
        } */}
        {/* <div className="button_remove">
          <Button
            onClick={(e) => {
              e.preventDefault();
              fileRef.current.click();
            }}
            style={{ marginRight: 0 }}
            marginRight="0"
            fontSize="1.3rem">
            הוספת תמונה
            <small>(לא חובה)</small>
          </Button>
          {isGetImg ||thanks.img? 
          <div className="ac-wrapper-close" onClick={()=>removePicture()}>
            <div className="ac-wrapper-close-inner">
              <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-times-circle fa-w-16 fa-5x"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 464c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm94.8-285.3L281.5 256l69.3 69.3c4.7 4.7 4.7 12.3 0 17l-8.5 8.5c-4.7 4.7-12.3 4.7-17 0L256 281.5l-69.3 69.3c-4.7 4.7-12.3 4.7-17 0l-8.5-8.5c-4.7-4.7-4.7-12.3 0-17l69.3-69.3-69.3-69.3c-4.7-4.7-4.7-12.3 0-17l8.5-8.5c4.7-4.7 12.3-4.7 17 0l69.3 69.3 69.3-69.3c4.7-4.7 12.3-4.7 17 0l8.5 8.5c4.6 4.7 4.6 12.3 0 17z" class=""></path></svg>
            </div>
            מחק תמונה 
          </div>
          :null}
        </div> */}

        {/* <input onChange={getImg} type="file" accept=".jpef, .png, .jpg" ref={fileRef} /> */}
      </form>

      {/* <Button onClick={done_thanks} disabled={!thanks.to || !thanks.from || !thanks.text}> */}
      {/* <Button onClick={done_thanks} disabled={!thanks.to || !thanks.text}>
        המשך
      </Button> */}
      <Button onClick={done_thanks}>Continue</Button>
      <ExamplesLink />
      <img
        src="/img/2022/ar.png"
        width="250px"
        height="357px"
        alt=""
        className="ac-ar-img"
      />
      <img
        src="/img/2022/MaskGroup5.png"
        width="250px"
        height="357px"
        alt=""
        className="ac-MaskGroup5-img"
      />
      <img
        src="/img/2022/arrow3.png"
        width="170px"
        height="209px"
        alt=""
        className="ac-arrow3-img"
      />
      <div className="ac-milk">
        <img
          src="/img/2022/milk.png"
          width="924px"
          height="444px"
          alt="arrow right decoration"
          className="ac-milk-img"
        />
        <img
          src="/img/2022/milk.png"
          width="924px"
          height="4445px"
          alt="arrow right decoration"
          className="ac-milk-imgmob"
        />
      </div>
    </div>
  );
};
