import TagManager from "react-gtm-module";
import { FacebookShareButton, WhatsappShareButton } from "react-share";

export const ShareButton = ({ thanks, keyClient, finaleImageImg }) => {
  console.log(thanks);
  // const shareValue = ` רוצה גם לעשות למישהו מתוק? המהדורה המיוחדת של מילקה מחכה לך עכשיו בחנויות, על כל אריזה מסר מתוק אחר.`;
  const shareValue = ` Do you also want to make a sweet moment? milka is waiting for you `;
  // const shareValueDekstop=`יש לך ברכה מ${thanks.from}.
  // גם לך מתחשק לעשות למישהו מתוק?
  // קליק לעיצוב ברכה משלך >>`;
  const handleOnSubmit = async (type) => {
    console.log(type);
    const response = await fetch(thanks.output);
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File([blob], `share.jpg`, { type: blob.type });
    console.log(file);
    if (navigator.share) {
      await navigator.share({
        title: "הנה משהו מתוק בשבילך",
        text: `${shareValue} >> https://dev.activated.digital/s/?u=${finaleImageImg}`,
        files: [file],
      });
      // .then(() => console.log('Successful share'))
      // .catch((error) => console.log('Error in sharing', error));
    } else {
      console.log(`system does not support sharing files.`);
    }
  };

  const DesktopV = () => {
    return (
      <div className="social-wrapper">
        <FacebookShareButton
          onClick={() => {
            TagManager.dataLayer({ dataLayer: { event: "share_facebook" } });
          }}
          url={`https://dev.activated.digital/s/?u=${finaleImageImg}`}
          // quote={shareValueDekstop}
          // title={shareMessage}
        >
          <img src="/img/2022/facebook.svg" alt="facebook" />
        </FacebookShareButton>
        {window.mobileCheck() && window.isIOS() ? (
          <WhatsappShareButton
            onClick={() => {
              TagManager.dataLayer({ dataLayer: { event: "share_whatsapp" } });
            }}
            url={`https://dev.activated.digital/s/?u=${finaleImageImg}`}
            //title={shareMessage}
          >
            <img src="/img/2022/whatsapp.svg" alt="whatsapp" />
          </WhatsappShareButton>
        ) : null}
        <img
          className="download_button"
          src="/img/2022/download.svg"
          alt="download"
          onClick={() => {
            TagManager.dataLayer({ dataLayer: { event: "download_event" } });
            const link = document.createElement("a");
            link.download = `Milka.jpg`;
            link.href = thanks.output;
            link.click();
          }}
        />
      </div>
    );
  };
  const MobileV = () => {
    return (
      <div className="social-wrapper">
        <div class="shareMobile" onClick={(e) => handleOnSubmit(e.target.alt)}>
          <img src="/img/2022/share.png" alt="Share" />
          Share
        </div>
        <div
          class="shareMobile"
          onClick={() => {
            const link = document.createElement("a");
            link.download = `Milka.jpg`;
            link.href = thanks.output;
            link.click();
          }}
        >
          <img src="/img/2022/Group21.png" alt="download" />
          Save
        </div>
      </div>
    );
  };
  return (
    <div className="shareButton">
      {window.mobileCheck() && !window.isIOS() ? <MobileV /> : <DesktopV />}
    </div>
  );
};
