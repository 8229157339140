import TagManager from "react-gtm-module";
import { Button } from "../../../components";
import { ExamplesLink } from "../../../components";

export const Preview = ({
  setStep,
  nextStep,
  setThanks,
  thanks,
  setTemplate,
  template,
}) => {
  const FinishSubmit1 = () => {
    nextStep();
    setTemplate(true);
    TagManager.dataLayer({
      dataLayer: { event: "automatic_blessing_submitted" },
    });
  };
  const FinishSubmit2 = () => {
    nextStep();
    setTemplate(false);
    TagManager.dataLayer({ dataLayer: { event: "manual_blessing_submitted" } });
  };
  console.log(template);
  return (
    <div className="preview">
      <h1>
       <span className="ac-difcolor multiply ">Perfect,<br/></span> right?
        <br />
        <small>this is how your greeting will look like</small>
      </h1>
      <div
        className="img-wrapper"
        style={{
          // background: `url(${thanks.design.src}) no-repeat`,
          backgroundSize: "100% 100%",
        }}
      >
        {/* {thanks.img?
        <div className='ac-chocoladimg-wrapper'>
            <img src={thanks.design.src} width="1200px" height="1200px" alt="milka preview at form" className='ac-formilka-img'/>
            <img src="/img/2022/top.png" width="1200px" height="1200px" alt="milka preview at form" className='ac-formilka2-img'/>
        </div>
        : */}

        {template ? (
          <div className="ac-chocoladimg-wrapper">
            <img
              src={thanks.design.src}
              width="1200px"
              height="1200px"
              alt="milka preview at form"
              className="ac-formilka-img"
            />
          </div>
        ) : (
          <div className="ac-chocoladimg-wrapper">
            <img
              src={thanks.design.src}
              width="1200px"
              height="1200px"
              alt="milka preview at form"
              className="ac-formilka-img"
            />
            <img
              src={thanks.design.srcCub}
              alt="milka preview at form"
              className="ac-formilka2-img"
            />
          </div>
        )}

        {window.mobileCheck() ? (
          <div className="bracha-text">
            {template ? (
              <>
                {/* <p>{thanks.design.postCardTxtTop}</p>
                <p>{thanks.design.postCardTxtBot}</p> */}
              </>
            ) : (
              <>
                <p>{thanks.to}</p>
                <p>{thanks.text}</p>
              </>
            )}
          </div>
        ) : (
          <div className="bracha-text">
            {template ? (
              <>
                {/* <p>{thanks.design.postCardTxtTop}</p>
                <p>{thanks.design.postCardTxtBot}</p> */}
              </>
            ) : (
              <>
                <p>{thanks.to}</p>
                <p>{thanks.text}</p>
              </>
            )}
          </div>
        )}

        {/* {thanks.img?
            !window.mobileCheck()?
            <div className="img_global_crop">
              <img  className="" alt="Milka" src={thanks.img} />
                </div>
                :
                <div className="img_global_crop" >
                  <img
                    className=""
                    alt="Milka"
                    src={thanks.img}
                   />
              </div>
          :null} */}
      </div>
      <div className="flex-on-desktop">
        {template ? (
          <Button
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: { event: "automatic_reedit" },
              });
              setStep(1);
              setTemplate(false);
            }}
          >
            Edit my greeting
          </Button>
        ) : (
          <Button
            onClick={() => {
              TagManager.dataLayer({ dataLayer: { event: "manual_reedit" } });
              setStep(1);
              setTemplate(false);
              console.log("manual_reedit");
            }}
          >
            Edit my greeting
          </Button>
        )}
        {template ? (
          <Button onClick={FinishSubmit1}>Send</Button>
        ) : (
          <Button onClick={FinishSubmit2}>Send</Button>
        )}
      </div>
      <ExamplesLink />
      <div className="ac-milk">
        <img
          src="/img/2022/milk.png"
          width="924px"
          height="444px"
          alt="arrow right decoration"
          className="ac-milk-img"
        />
        <img
          src="/img/2022/milk.png"
          width="924px"
          height="4445px"
          alt="arrow right decoration"
          className="ac-milk-imgmob"
        />
      </div>
    </div>
  );
};
