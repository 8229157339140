import TagManager from "react-gtm-module";
import { ExamplesLink } from "../../../components";
import { Button } from "../../../components/Button/Button";
import pdf from "../../../assets/takanon.pdf";
import { useState } from "react";
import { FormBegin } from "./FormBegin";

export const Agree = ({ agree, setAgree, nextStep }) => {
  const Submit = () => {
    nextStep();
    TagManager.dataLayer({ dataLayer: { event: "create_blessing" } });
  };

  return (
    <div className="home">
      <div className="ac-wrapper-parts ac-wrapper-parts1">
        <div className="ac-part ac-left-part">
          <div className="ac-chocolads">
            <img
              src="/img/2022/chocolads.png"
              width="535px"
              height="495px"
              alt="milka chocolad"
              className="ac-chocolads-img"
            />
            <img
              src="/img/2022/chocoladsmobnew.png"
              width="423px"
              height="168px"
              alt="milka chocolad"
              className="ac-chocolads-imgmob"
            />
          </div>
        </div>
        <div className="ac-part ac-right-part">
          <div className="ac-title-wrapper ">
          <span className="ac-bo">milka invites you to create tender moments <br/> and send your loved ones</span>
            <br /> <span class="ac-fwb ">New Year's wishes</span>{" "}
            {/* <span className="ac-sm">
              <br className="ac-show-on-mobile" /> מכל ה{" "}
              <span className="ac-dash">-</span>
            </span> */}
          </div>
          {/* <div className="ac-cube1">
            <img
              src="/img/2022/cube1.png"
              width="422px"
              height="340px"
              alt="milka cube1"
              className="ac-cube1-img"
            />
          </div> */}
        </div>
      </div>
      <div className="ac-wrapper-parts ac-wrapper-parts2">
      <div className="ac-text-wrapper">
          Is there anything more tender
          <br />
          than receiving a sweet greeting for the New Year?
          <br />
          <span className="ac-difcolor multiply ">Yes! Sending one!</span>
          <br />
          <br />
          You are invited to spread happiness, support, and<br/>
           uplift the people you love the most during the holidays
        </div>

        <Button onClick={Submit}>
          <p className="text">For your tender greeting</p>
        </Button>
      </div>
      <div className="ac-ar1">
        <img
          src="/img/2022/ar1.png"
          width="159px"
          height="254px"
          alt="arrow right decoration"
          className="ac-ar1-img"
        />
        <img
          src="/img/2022/mob2.png"
          width="135px"
          height="135px"
          alt="arrow right decoration"
          className="ac-ar1-imgmob"
        />
      </div>
      <div className="ac-milk">
        <img
          src="/img/2022/milk.png"
          width="924px"
          height="444px"
          alt="arrow right decoration"
          className="ac-milk-img"
        />
        <img
          src="/img/2022/milk.png"
          width="924px"
          height="4445px"
          alt="arrow right decoration"
          className="ac-milk-imgmob"
        />
      </div>
      <div className="ac-ar2">
        <img
          src="/img/2022/ac-arr2.png"
          width="153px"
          height="82px"
          alt="arrow right decoration"
          className="ac-ar2-img"
        />
        <img
          src="/img/2022/mob1.png"
          width="108px"
          height="82px"
          alt="arrow right decoration"
          className="ac-ar2-imgmob"
        />
      </div>

      <ExamplesLink />
    </div>
  );
};
