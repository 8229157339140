import TagManager from 'react-gtm-module'
import { Link } from 'react-router-dom';
import './ExamplesLink.css';

export const ExamplesLink = () => (
  <Link className="examples" to="/examples">
     <img  
        // onClick={() => {TagManager.dataLayer({dataLayer: {event: 'see_samples'}})}}
           // src="/img/2023/milka.svg" width="254px" height="123px" alt="milka logo" />
        // src="/img/2023/milka-logo.svg" width="254px" height="123px" alt="milka logo" />
        src="/img/2022/milka.png" width="254px" height="123px" alt="milka logo" />
     
  </Link>
);
