import { useEffect } from "react";
import { ExamplesLink } from "../../../components";
import { Button } from "../../../components";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TagManager from "react-gtm-module";

const images = [
  {
    src: "/img/2022/back.png",
    srcCub: "/img/2022/choco/appel.png",
    postCardTxtTop: "שנה",
    postCardTxtBot: "טובה",
    left: "0",
    right: "0",
    width: "100%",
    height: "100%",
    textColor: "#7d69ac",
    fontSize: "2vh",
    dominantBaseline: "middle",

    fontSizeMobile: "3.5vw",
    maxThanksTo: 6,
    maxThanksText: 5,
    dominantBaseline: "middle",
  },
  {
    src: "/img/2022/back.png",
    srcCub: "/img/2022/choco/omegranate.png",
    postCardTxtTop: "שנה",
    postCardTxtBot: "טובה",
    textColor: "#7d69ac",
    fontSize: "1.6vh",
    dominantBaseline: "middle",
    textColorMobile: "#7d69ac",

    maxThanksTo: 6,
    maxThanksText: 5,
    dominantBaseline: "middle",
  },
  {
    src: "/img/2022/back.png",
    srcCub: "/img/2022/choco/honey.png",
    postCardTxtTop: "שנה",
    postCardTxtBot: "טובה",
    textColor: "#7d69ac",
    fontSize: "1.7vh",
    dominantBaseline: "middle",

    fontSizeMobile: "2.7vw",
    textColorMobile: "#7d69ac",

    maxThanksTo: 6,
    maxThanksText: 5,
    dominantBaseline: "middle",

    maxThanksTo: 6,
    maxThanksText: 5,
    dominantBaseline: "middle",
  },
  {
    src: "/img/2022/back.png",
    srcCub: "/img/2022/choco/fish.png",
    postCardTxtTop: "שנה",
    postCardTxtBot: "טובה",
    textColor: "#7d69ac",
    fontSize: "2.2vh",
    dominantBaseline: "middle",

    fontSizeMobile: "3.6vw",
    textColorMobile: "#7d69ac",

    maxThanksTo: 6,
    maxThanksText: 5,
    dominantBaseline: "middle",
  },
  {
    src: "/img/2022/back.png",
    srcCub: "/img/2022/choco/magen.png",
    postCardTxtTop: "שנה",
    postCardTxtBot: "טובה",
    textColor: "#7d69ac",
    fontSize: "2.2vh",
    dominantBaseline: "middle",

    fontSizeMobile: "3.6vw",
    textColorMobile: "#7d69ac",

    maxThanksTo: 6,
    maxThanksText: 5,
    dominantBaseline: "middle",
  },

  {
    src: "/img/2022/back.png",
    srcCub: "/img/2022/choco/lev.png",
    postCardTxtTop: "שנה",
    postCardTxtBot: "טובה",
    textColor: "#7d69ac",
    fontSize: "2.2vh",
    dominantBaseline: "middle",

    fontSizeMobile: "3.6vw",
    textColorMobile: "#7d69ac",

    maxThanksTo: 6,
    maxThanksText: 5,
    dominantBaseline: "middle",
  },
];

export const ChooseShokolad = ({
  nextStep,
  setStep,
  setThanks,
  thanks,
  setTemplate,
}) => {
  const FinishSubmit = () => {
    setStep(4);
    setTemplate(false);
    TagManager.dataLayer({ dataLayer: { event: "finish_write" } });
  };

  let sliderStyle = { width: "auto", maxHeight: "16vw", height: "16vw" };
  let sliderLeftBtnStyle = { left: "1vw" };
  let sliderRightBtnStyle = { right: "1vw" };
  let isMobile = false;
  const wWidth = window.innerWidth;
  if (wWidth < 600) {
    isMobile = true;
    sliderStyle = { width: "auto", maxHeight: "45vw" };
    sliderLeftBtnStyle = { left: "5vw" };
    sliderRightBtnStyle = { right: "5vw" };
  }

  useEffect(() => {
    setThanks({ ...thanks, design: (new Image().src = images[0]) });
    // eslint-disable-next-line
  }, []);

  function PrevButton({ onClick }) {
    return (
      <button
        onClick={onClick}
        className="slick-prev"
        style={sliderLeftBtnStyle}
      >
        <img src="/img/2022/chevron-left.svg" alt="arrow left" />
      </button>
    );
  }

  function NextButton({ onClick }) {
    return (
      <button
        onClick={onClick}
        className="slick-next"
        style={sliderRightBtnStyle}
      >
        <img src="/img/2022/chevron-right.svg" alt="arrow right" />
      </button>
    );
  }

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
    // centerMode: true,
    // centerPadding: '60px',
    beforeChange: (currentIndex, nextIndex) => {
      setThanks({ ...thanks, design: (new Image().src = images[nextIndex]) });
    },
    responsive: [
      {
        breakpoint: 100,
        settings: {
          // centerMode: true,
          // centerPadding: '40px',
          slidesToShow: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // centerMode: true,
          // centerPadding: '40px',
          slidesToShow: 1,
          autoplay: false,
        },
      },
    ],
  };
  const SubmitNowMe = () => {
    nextStep();
    setTemplate(false);
    TagManager.dataLayer({ dataLayer: { event: "now_me" } });
  };

  return (
    <div className="choose-desgin">
      <h1>
        choose your<br/>
        <span className="ac-difcolor multiply "> Milka:</span>
      </h1>
      <Slider {...settings}>
        {images.map((img, i) => (
          <div key={i}>
            {/* <img src={img.src} alt="card" style={{ width: 'auto', maxHeight: '53vh' }} /> */}
            <img
              src={img.src}
              alt="card"
              style={sliderStyle}
              className="ac-hidden"
            />
            <div className="ac-top">
              <img src={img.srcCub} alt="" style={sliderStyle} />
            </div>
            {/* <div className='ac-postcard-txt'>
              <span className='ac-postcard-txttop'>{img.postCardTxtTop}</span><br/>
              <span className='ac-postcard-txtbot'>{img.postCardTxtBot}</span>
            </div> */}
          </div>
        ))}
      </Slider>
      {/* <div className='ac-top'>
         <img src="/img/2022/top.png" width="1200px" height="1200px" alt="milka cube1" className='ac-top-img'/>
      </div> */}
      <div className="ac-choose-btns">
        {/* <Button disabled={!thanks.design} onClick={SubmitNowMe}>
          רוצה לכתוב מסר בעצמי
        </Button> */}

        <Button onClick={FinishSubmit}>Let's see how it came out</Button>
      </div>

      <ExamplesLink />
      <div className="ac-milk">
        <img
          src="/img/2022/milk.png"
          width="924px"
          height="444px"
          alt="arrow right decoration"
          className="ac-milk-img"
        />
        <img
          src="/img/2022/milk.png"
          width="924px"
          height="4445px"
          alt="arrow right decoration"
          className="ac-milk-imgmob"
        />
      </div>
    </div>
  );
};
