import { useEffect, useState } from "react";
import {
  Agree,
  ChooseDesign,
  ChooseShokolad,
  Preview,
  EditText,
  Form,
  Finish,
  Confirmation,
} from "./steps";

import "./Home.css";
// import { encrypt, getKey } from "../../Utils/outlis";  //Changed 13.08.2023
import { useLocation } from "react-router";
import { SavePictData } from "../../Utils/savePicture";
import { ConfirmUpload } from "../ConfirmUpload/ConfirmUpload";
import { Amazing } from "../Amazing/Amazing";
import { savePictureUpdate } from "../../Utils/savePictureUpdate";

const initialState = {
  design: {
    src: "",
    left: "0",
    right: "0",
    bottom: "0",
    width: "100%",
    height: "100%",
  },
  from: "",
  to: "",
  text: "",
  img: "",
  name: "",
  phone: "",
  email: "",
  isSend: false,
  output: null,
  utmSource: "",
  utmMedium: "",
  utm_val: "",
  IP: "",
};

export const Home = () => {
  const location = useLocation();

  const [agree, setAgree] = useState(false);
  const [notSave, setNotSave] = useState(true);
  const [step, setStep] = useState(0);
  const [thanks, setThanks] = useState(initialState);
  // const [keyClient, setKeyClient] = useState(getKey(4));
  const [finaleImageImg, setFinaleImageImg] = useState("");
  const [template, setTemplate] = useState(false);
  const [IP, setIP] = useState("");

  const nextStep = () => setStep(step + 1);
  const next2Step = () => setStep(step + 2);

  useEffect(() => {
    const getDataIP = async () => {
      const response = await fetch("https://geolocation-db.com/json/");
      const data = await response.json();
      setIP(data.IPv4 !== "" ? data.IPv4 : "null");
      setThanks({
        ...thanks,
        IP: data.IPv4 !== "" ? data.IPv4 : "null",
        utmSource: new URLSearchParams(location?.search).get("utm_source"),
        utmMedium: new URLSearchParams(location?.search).get("utm_medium"),
      });
    };
    if (IP === "") {
      getDataIP();
    }

   
  });

  useEffect(() => {
    if (notSave) {
      if (thanks.output != null) {
        if (step === 4) {
          setNotSave(false);
          // saveBeforeData();
        }
      }
    }
  });
  // const saveBeforeData = async () => {
  //   let value = new SavePictData(
  //     keyClient,
  //     thanks.IP,
  //     thanks.utm_val,
  //     thanks.utmSource,
  //     thanks.utmMedium,
  //     agree,
  //     thanks.from,
  //     thanks.to,
  //     thanks.text,
  //   );
  //   let valueUpdate = new savePictureUpdate(
  //     keyClient,
  //     agree,
  //     thanks.from,
  //     thanks.to,
  //     thanks.text,
  //   );

  // if(thanks.isSend){
  //   const enc = encrypt(JSON.stringify(valueUpdate));
  //   fetch('https://api.dev.activated.digital/milkatalk/postSecureUpdate', {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
  //     body: JSON.stringify({ data: enc,output: thanks.output}),
  //   }).then(() => {
  //     setNotSave(false);
  //     console.log(thanks.output);
  //   });
  // }else{
  //   const enc = encrypt(JSON.stringify(value));
  //   fetch('https://api.dev.activated.digital/milkatalk/postSecure', {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
  //     body: JSON.stringify({ data: enc,output: thanks.output}),
  //   }).then(() => {
  //     setNotSave(false);
  //   });
  // }
  // };

  // const ChangeKey = () => setKeyClient(getKey());

  switch (step) {
    case 0:
      return (
        <Agree
          agree={agree}
          setAgree={setAgree}
          nextStep={nextStep}
          // keyClient={keyClient}  //Changed 13.08.2023
          thanks={thanks}
          setThanks={setThanks}
        />
      );
    case 1:
      return (
        <ChooseDesign
          thanks={thanks}
          setThanks={setThanks}
          nextStep={nextStep}
          setStep={setStep}
          setTemplate={setTemplate}
          template={template}
        />
      );
    case 2:
      return (
        <EditText
          thanks={thanks}
          setThanks={setThanks}
          nextStep={nextStep}
          setTemplate={setTemplate}
          template={template}
        />
      );
    case 3:
      return (
        <ChooseShokolad
          thanks={thanks}
          setThanks={setThanks}
          nextStep={nextStep}
          setStep={setStep}
          setTemplate={setTemplate}
          template={template}
        />
      );
    case 4:
      return (
        <Preview
          thanks={thanks}
          setThanks={setThanks}
          nextStep={nextStep}
          setStep={setStep}
          setTemplate={setTemplate}
          template={template}
        />
      );

    case 5:
      return (
        <Finish
          // keyClient={keyClient}  //Changed 13.08.2023
          // ChangeKey={ChangeKey} //Changed 13.08.2023
          thanks={thanks}
          setThanks={setThanks}
          nextStep={nextStep}
          setStep={setStep}
          initialState={initialState}
          next2Step={next2Step}
          setTemplate={setTemplate}
          template={template}
          setFinaleImageImg={setFinaleImageImg}
          finaleImageImg={finaleImageImg}
        />
      );
    // case 6:
    //   return (
    //     <Form keyClient={keyClient} thanks={thanks} setThanks={setThanks} nextStep={nextStep} />
    //   );
    // case 7:
    //   return (
    //     <Confirmation
    //       hanks={thanks}
    //       keyClient={keyClient}
    //       thanks={thanks}
    //       setThanks={setThanks}
    //       nextStep={nextStep}
    //       next2Step={next2Step}
    //       phoneNumber={thanks?.phone}
    //     />
    //   );
    // case 7:
    //   return (
    //     <ConfirmUpload
    //         thanks={thanks}
    //         keyClient={keyClient}
    //     />
    //   );
    // case 8:
    //   return (
    //     <Amazing
    //         thanks={thanks}
    //         keAmazingyClient={keyClient}
    //     />
    //   );
    default:
      return <Agree agree={agree} setAgree={setAgree} nextStep={nextStep} />;
  }
};
