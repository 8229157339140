import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import Loader from "react-loader-spinner";
import { Button } from "../../components";

export const SharePicture = () => {
  const location = useRouteMatch();
  const [loader, setLoader] = useState(true);
  const [exist, setExist] = useState(undefined);

  const [valueCurrent, setvalueCurrent] = useState();

  useEffect(() => {
    if (loader) {
      fetch(
        `https://api.dev.activated.digital/milkatalk/posts/${location?.params?.key}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
        .then((response) => response.json())
        .then((resp) => {
          if (resp.picturethanks) {
            setvalueCurrent(resp);
            setExist(true);
            setLoader(false);
          } else {
            setExist(false);
            setLoader(false);
          }
        })
        .catch(() => {
          setExist(false);
          setLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const BlockResponse = ({ text }) => {
    return (
      <div
        className="ac-container  updatepicture"
        style={{ textAlign: "center", marginBottom: "4vh" }}
      >
        <div className="ac-popup-title">
          <span className="ac-title-name ">{text}</span>
        </div>
      </div>
    );
  };
  const ButtonTryAgain = () => {
    return (
      <Button
        onClick={() => {
          window.location.replace("https://dev.activated.digital/");
        }}
      >
        רוצה לעצב גלויה משלך?
      </Button>
    );
  };
  if (loader) {
    return (
      <div className="ac-loader-spinner" style={{ textAlign: "center" }}>
        <Loader type="ThreeDots" color="#fff" height={100} width={100} />
      </div>
    );
  }

  if (exist)
    return (
      <div className="shareWithUrl">
        <h2>{valueCurrent?.fromwhomthanks} רוצה לומר לך תודה</h2>
        <div className="img_share">
          <img
            src={valueCurrent?.picturethanks}
            className="shareWithUrlPict"
            alt="thanks MilkaTalk"
          />
        </div>
        <ButtonTryAgain />
      </div>
    );

  return (
    <div className="notUrlValid">
      <BlockResponse text={`נא להתחבר עם הקישור שנשלח לך `} />
      <ButtonTryAgain />
    </div>
  );
};
