import "./Button.css";

export const Button = ({
  children,
  onClick,
  disabled = false,
  marginRight = "auto",
  // fontSize = '4vh',
  background = "#fff",
  inverted = false,
  wide = false,
  fill = "#fff",
  translateX = false,
  className = "",
}) => {
  return (
    <button
      onClick={onClick}
      className={`btn ${inverted ? "inverted" : ""} ${
        wide ? "wide" : ""
      } ${className}`}
      disabled={disabled}
      style={{ marginRight, background }}
    >
      {children}
      <div className="ac-twoarr">
        {/* <img src="/img/2022/twoarr.svg" width="24px" height="22px" alt="arrow right" className='ac-twoarr-img'/> */}
        {`>>`}
      </div>
      {/* <span className={`arrows ${translateX ? 'translateX' : ''}`}>
        {marginRight === 'auto' ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22.924"
            height="12.519"
            style={{ verticalAlign: 'middle' }}
            viewBox="0 0 22.924 12.519">
            <g id="Group_2572" data-name="Group 2572" transform="translate(-46.205 -439.376)">
              <g id="Group_2506" data-name="Group 2506" transform="translate(58.287 439.376)">
                <path
                  id="Path_2586"
                  data-name="Path 2586"
                  d="M0-.891,10.841,5.369V-7.15Z"
                  transform="translate(0 7.15)"
                  fill={fill}
                />
              </g>
              <g id="Group_2507" data-name="Group 2507" transform="translate(46.205 439.376)">
                <path
                  id="Path_2587"
                  data-name="Path 2587"
                  d="M0-.891,10.841,5.369V-7.15Z"
                  transform="translate(0 7.15)"
                  fill={fill}
                />
              </g>
            </g>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10.483"
            height="19.195"
            viewBox="0 0 10.483 19.195">
            <g id="Group_2574" data-name="Group 2574" transform="translate(-237.552 -558.922)">
              <g id="Group_2481" data-name="Group 2481" transform="translate(237.552 569.039)">
                <path
                  id="Path_2554"
                  data-name="Path 2554"
                  d="M-1.909,0-7.15,9.078H3.333Z"
                  transform="translate(7.15 0)"
                  fill={fill}
                />
              </g>
              <g id="Group_2482" data-name="Group 2482" transform="translate(237.552 558.922)">
                <path
                  id="Path_2555"
                  data-name="Path 2555"
                  d="M-1.909,0-7.15,9.078H3.333Z"
                  transform="translate(7.15 0)"
                  fill={fill}
                />
              </g>
            </g>
          </svg>
        )}
      </span> */}
    </button>
  );
};
