import { useState, useRef, useEffect } from "react";
import $ from "jquery";
import html2canvas from "html2canvas";
import { ExamplesLink } from "../../../components";

import { WhatsappShareButton, FacebookShareButton } from "react-share";
import { Button } from "../../../components";

import TagManager from "react-gtm-module";
import { ShareButton } from "../../../components/ShareButton/ShareButton";

export const Finish = ({
  keyClient,
  ChangeKey,
  nextStep,
  setThanks,
  thanks,
  initialState,
  setStep,
  next2Step,
  template,
  finaleImageImg,
  setFinaleImageImg,
}) => {
  const [isSavePicture, setIsSavePicture] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    const wWidth = window.innerWidth;
    let isMobile = false;
    if (wWidth < 1000) {
      isMobile = true;
    }

    var domainsToDecorate = [
        "dev.activated.digital", //add or remove domains (without https or trailing slash)
        "dev.activated.digital",
      ],
      queryParams = [
        "u", //add or remove query parameters you want to transfer
      ];
    // do not edit anything below this line
    var links = document.querySelectorAll("a");

    // check if links contain domain from the domainsToDecorate array and then decorates
    for (var linkIndex = 0; linkIndex < links.length; linkIndex++) {
      for (
        var domainIndex = 0;
        domainIndex < domainsToDecorate.length;
        domainIndex++
      ) {
        if (
          links[linkIndex].href.indexOf(domainsToDecorate[domainIndex]) > -1 &&
          links[linkIndex].href.indexOf("#") === -1
        ) {
          links[linkIndex].href = decorateUrl(links[linkIndex].href);
        }
      }
    }
    // decorates the URL with query params
    function decorateUrl(urlToDecorate) {
      urlToDecorate =
        urlToDecorate.indexOf("?") === -1
          ? urlToDecorate + "?"
          : urlToDecorate + "&";
      var collectedQueryParams = [];
      for (var queryIndex = 0; queryIndex < queryParams.length; queryIndex++) {
        if (getQueryParam(queryParams[queryIndex])) {
          collectedQueryParams.push(
            queryParams[queryIndex] +
              "=" +
              getQueryParam(queryParams[queryIndex])
          );
        }
      }
      return urlToDecorate + collectedQueryParams.join("&");
    }

    // borrowed from https://stackoverflow.com/questions/831030/
    // a function that retrieves the value of a query parameter
    function getQueryParam(name) {
      if (
        (name = new RegExp("[?&]" + encodeURIComponent(name) + "=([^&]*)").exec(
          window.location.search
        ))
      )
        return decodeURIComponent(name[1]);
    }

    if (!isSavePicture) {
      setIsSavePicture(true);
      html2canvas(ref.current, {
        onrendered: function (canvas) {
          var imgsrc = canvas.toDataURL("image/png");
          console.log(imgsrc);
          $("#newimg").attr("src", imgsrc);
          $("#img").show();
        },
        scale: isMobile ? 3.15 : 1.6,
        //  width: 2400,
        // height: 1260,
        // allowTaint: true,
        // scale: isMobile ? 3 : 1.3,
        // scrollX:window.pageXOffset - 100,
        // scrollY:window.pageYOffset - 200,
        // scrollY: -window.scrollY
      })
        .then((canvas) => {
          // var ctx = canvas.getContext('2d');
          // ctx.beginPath();
          // ctx.textAlign = "right";
          // ctx.textBaseline = "middle"; // set text in center of place vertically
          // ctx.fillText("sample text", 100, 100);
          // ctx.closePath();
          //     console.log(ctx);
          //     console.log(canvas);
          $(document).ready(function () {
            var dataURL = canvas.toDataURL();
            $("#newimg").attr("src", dataURL);
            setThanks({ ...thanks, output: dataURL });
            // AJAX request
            $.ajax({
              // url: "s/createimg/image.php",
              url: "https://dev.activated.digital/s/createimg/image.php",
              type: "post",
              data: { imgBase64: dataURL },
              success: function (data) {
                console.log(data);
                var str = data.substring(data.indexOf("/") + 1);
                str = str.split(".")[0];
                str = str.split(".")[0];
                setFinaleImageImg(str);
                console.log(typeof str);
                console.log("Upload successfully");
                $(".ac-loader").fadeOut();
                $(".social-wrapper").attr(
                  "style",
                  "opacity:1;pointer-events: unset"
                );
                $(".shareButton").fadeIn();
              },
            });
          });

          console.log(canvas.toDataURL("image/png", 1, 0));
        })
        .catch((e) => {
          alert(e);
        });
    }
  }, [isSavePicture]);

  const toCompetition = () => {
    TagManager.dataLayer({ dataLayer: { event: "to_competition" } });
    nextStep();
  };
  return (
    <div>
      <div className={`finish ${isSavePicture ? `border_img_finish` : ``}`}>
        <h1>
        where to <span className="ac-difcolor multiply ">sent to?</span>
        </h1>
        {/*Here is the Canvas Photo That USer dont see */}
        <div
          ref={ref}
          id="createImg"
          className="img-wrapper"
          style={{
            background: `url(https://dev.activated.digital/img/2022/bck.jpg) no-repeat`,
            backgroundSize: "226% 218%",
            backgroundPosition: "51% 58%",
          }}
        >
          {/* <div className='ac-chocoladimg-wrapper'> */}
          {template ? (
            <>
              <img
                src={thanks.design.src}
                width="1200px"
                height="630px"
                alt="milka preview at form"
                className="ac-formilka-img"
                id="newimg"
              />
            </>
          ) : (
            <>
              <img
                src={thanks.design.src}
                width="1200px"
                height="630px"
                alt="milka preview at form"
                className="ac-formilka-img"
                id="newimg"
              />
              <img
                src={thanks.design.srcCub}
                width="1200px"
                height="630px"
                alt="milka preview at form"
                className="ac-formilka2-img"
              />
            </>
          )}
          {/* <img src={thanks.design.src} width="600px" height="315px" alt="milka preview at form" className='ac-formilka-img' id="newimg"/>
              <img src="/img/2022/top.png" width="600px" height="315px" alt="milka preview at form" className='ac-formilka2-img'/> */}
          {/* <p className="bracha-text1">{thanks.to}</p>
              <p className="bracha-text2">{thanks.text}</p> */}
          {/* <img src="" id="img" alt=""/> */}
          {/* </div> */}
          {window.mobileCheck() ? (
            <div className="bracha-text">
              {template ? (
                <>
                  {/* <p
                    style={{
                      dominantBaseline: thanks.design.dominantBaseline,
                      fontSize: "7.5vw",
                      height: "unset",
                      position: "relative",
                      marginBlockStart: "0",
                      marginBlockEnd: "0",
                      textAlign: "right",
                    }}
                  >
                    {thanks.design.postCardTxtTop}
                  </p> */}
                  {/* <p
                    style={{
                      dominantBaseline: "middle",
                      fontSize: "14vw",
                      right: "4.5vw",
                      top: "0.9vw",
                      height: "0vw",
                      position: "relative",
                      marginBlockStart: "0",
                      marginBlockEnd: "0",
                      textAlign: "right",
                      height: "5.7vw",
                      lineHeight: "0.5",
                      verticalAlign: "bottom",
                    }}
                  >
                    {thanks.design.postCardTxtBot}
                  </p> */}
                </>
              ) : (
                <>
                  <p
                    style={{
                      dominantBaseline: thanks.design.dominantBaseline,
                       // fontSize: "7vw",
                       fontSize: "4vw",
                      height: "unset",
                      position: "relative",
                      marginBlockStart: "0",
                      marginBlockEnd: "0",
                      textAlign: "center",
                      top: "0vw",
                    }}
                  >
                    {thanks.to}
                  </p>
                  <p
                    style={{
                      dominantBaseline: "middle",
                       // fontSize: "7vw",
                       fontSize: "4vw",
                      right: "0vw",
                      // top: "-1vw",
                      top:"0.6vw",
                      height: "5.7vw",
                      position: "relative",
                      marginBlockStart: "0",
                      marginBlockEnd: "0",
                      textAlign: "center",
                      lineHeight: "0.7",
                      verticalAlign: "bottom",
                    }}
                  >
                    {thanks.text}
                  </p>
                </>
              )}
            </div>
          ) : (
            <div className="bracha-text">
              {template ? (
                <>
              {/* <p
                  style={{
                    dominantBaseline: thanks.design.dominantBaseline,
                    fontSize: "5vw",
                    height: "unset",
                    position: "relative",
                    marginBlockStart: "0",
                    marginBlockEnd: "0",
                    textAlign: "right",
                    top: "-1.7vw",
                  }}
                >
                  {thanks.to}
                </p>
                <p
                  style={{
                    dominantBaseline: "middle",
                    fontSize: "9.5vw",
                    right: "2vw",
                    top: "-4vw",
                    height: "unset",
                    position: "relative",
                    marginBlockStart: "0",
                    marginBlockEnd: "0",
                    textAlign: "right",
                    height: "5.7vw",
                    lineHeight: "0.7",
                    verticalAlign: "bottom",
                  }}
                >
                  {thanks.text}
                </p> */}
              </>
              ) : (
                <>
                  <p
                    style={{
                      dominantBaseline: thanks.design.dominantBaseline,
                      // fontSize: "3.1vw",
                      fontSize: "1.8vw",
                      height: "unset",
                      position: "relative",
                      marginBlockStart: "0",
                      marginBlockEnd: "0",
                      textAlign: "center",
                      top: "-1.7vw",
                      letterSpacing:"0.05vw"
                    }}
                  >
                    {thanks.to}
                  </p>
                  <p
                    style={{
                      dominantBaseline: "middle",
                      fontSize: "1.8vw",
                      right: "0vw",
                      // top: "-2.1vw",
                      top:"-1.4vw",
                      height: "unset",
                      position: "relative",
                      marginBlockStart: "0",
                      marginBlockEnd: "0",
                      textAlign: "center",
                      height: "5.7vw",
                      lineHeight: "0.7",
                      verticalAlign: "bottom",
                      letterSpacing:"0.05vw"
                    }}
                  >
                    {thanks.text}
                  </p>
                </>
              )}
            </div>
          )}
        </div>
        {/*End Here is the Canvas Photo That USer dont see */}
        <div
          className="ac-hidecanvasscreen"
          style={{
            background: `url(https://dev.activated.digital/img/2022/bck-mob2.jpg) no-repeat`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        {/*Here is the Preview That USer see */}

        <div
          id="forUserPreview"
          style={{
            background: `url(https://dev.activated.digital/img/2022/bck.jpg) no-repeat`,
            backgroundSize: "226% 218%",
            backgroundPosition: "51% 58%",
          }}
        >
          {template ? (
            <>
              <img
                src={thanks.design.src}
                width="1200px"
                height="630px"
                alt="milka preview at form"
                className="ac-formilka-img"
                id="newimg"
              />
            </>
          ) : (
            <>
              <img
                src={thanks.design.src}
                width="1200px"
                height="630px"
                alt="milka preview at form"
                className="ac-formilka-img"
                id="newimg"
              />
              <img
                src={thanks.design.srcCub}
                width="1200px"
                height="630px"
                alt="milka preview at form"
                className="ac-formilka2-img"
              />
            </>
          )}

          {window.mobileCheck() ? (
            <div className="forUserPreviewbracha-text">
              {template ? (
                <>
                  {/* <p
                    style={{
                      dominantBaseline: thanks.design.dominantBaseline,
                      fontSize: "7.5vw",
                      height: "unset",
                      position: "relative",
                      marginBlockStart: "0",
                      marginBlockEnd: "0",
                      textAlign: "right",
                    }}
                  >
                    {thanks.design.postCardTxtTop}
                  </p> */}
                  {/* <p
                    style={{
                      dominantBaseline: "middle",
                      fontSize: "14vw",
                      right: "4.5vw",
                      top: "1.2vw",
                      height: "5.7vw",
                      position: "relative",
                      marginBlockStart: "0",
                      marginBlockEnd: "0",
                      textAlign: "right",
                      height: "5.7vw",
                      lineHeight: "0.7",
                      verticalAlign: "bottom",
                    }}
                  >
                    {thanks.design.postCardTxtBot}
                  </p> */}
                </>
              ) : (
                <>
                  <p
                    style={{
                      dominantBaseline: thanks.design.dominantBaseline,
                       // fontSize: "7vw",
                       fontSize: "4vw",
                      height: "unset",
                      position: "relative",
                      marginBlockStart: "0",
                      marginBlockEnd: "0",
                      textAlign: "center",
                    }}
                  >
                    {thanks.to}
                  </p>
                  <p
                    style={{
                      dominantBaseline: "middle",
                      // fontSize: "7vw",
                      fontSize: "4vw",
                      right: "0vw",
                      // top: "-0vw",
                      top:"1.4vw",
                      height: "5.7vw",
                      position: "relative",
                      marginBlockStart: "0",
                      marginBlockEnd: "0",
                      textAlign: "center",
                      height: "5.7vw",
                      lineHeight: "0.7",
                      verticalAlign: "bottom",
                    }}
                  >
                    {thanks.text}
                  </p>
                </>
              )}
            </div>
          ) : (
            <div className="forUserPreviewbracha-text">
              {template ? (
                <>
                  {/* <p
                    style={{
                      dominantBaseline: thanks.design.dominantBaseline,
                      fontSize: "4.5vw",
                      height: "unset",
                      position: "relative",
                      marginBlockStart: "0",
                      marginBlockEnd: "0",
                      textAlign: "right",
                    }}
                  >
                    {thanks.design.postCardTxtTop}
                  </p>
                  <p
                    style={{
                      dominantBaseline: "middle",
                      fontSize: "8.6vw",
                      right: "1vw",
                      top: "0.2vw",
                      height: "unset",
                      position: "relative",
                      marginBlockStart: "0",
                      marginBlockEnd: "0",
                      textAlign: "right",
                      height: "5.7vw",
                      lineHeight: "0.7",
                      verticalAlign: "bottom",
                    }}
                  >
                    {thanks.design.postCardTxtBot}
                  </p> */}
                </>
              ) : (
                <>
                  <p
                    style={{
                      dominantBaseline: thanks.design.dominantBaseline,
                      // fontSize: "3.1vw",
                      fontSize: "1.8vw",
                      height: "unset",
                      position: "relative",
                      marginBlockStart: "0",
                      marginBlockEnd: "0",
                      textAlign: "center",
                      top: "-1.7vw",
                      letterSpacing:"0.05vw"
                    }}
                  >
                    {thanks.to}
                  </p>
                  <p
                    style={{
                      dominantBaseline: "middle",
                      // fontSize: "3.1vw",
                      fontSize: "1.8vw",
                      right: "0vw",
                         // top: "-2.1vw",
                         top:"-1.4vw",
                      height: "unset",
                      position: "relative",
                      marginBlockStart: "0",
                      marginBlockEnd: "0",
                      textAlign: "center",
                      height: "5.7vw",
                      lineHeight: "0.7",
                      verticalAlign: "bottom",
                      letterSpacing:"0.05vw"
                    }}
                  >
                    {thanks.text}
                  </p>
                </>
              )}
            </div>
          )}
        </div>
        {/*End Here is the Preview That USer see */}

        <div className="flex-on-desktop">
          <div className="ac-loader">
            <img
              src="/img/2022/loading.gif"
              alt="loading"
              className="ac-loaderimg"
            />
            <div>greetings will be ready shortly</div>
          </div>

          <ShareButton
            thanks={thanks}
            keyClient={keyClient}
            finaleImageImg={finaleImageImg}
          />
          {/* <Button background="#7462a0" onClick={nextStep}>
                לתחרות
            </Button>*/}
          <Button
            onClick={() => {
              TagManager.dataLayer({ dataLayer: { event: "start_over" } });
              if (window.confirm(`Are you sure you want to delete the greetings you wrote?`)) {
                setStep(0);
                ChangeKey();
                setThanks(initialState);
              }
            }}
          >
            I would like to start over
          </Button>
        </div>

        <ExamplesLink />
        <div className="ac-milk">
          <img
            src="/img/2022/milk.png"
            width="924px"
            height="444px"
            alt="arrow right decoration"
            className="ac-milk-img"
          />
          <img
            src="/img/2022/milk.png"
            width="924px"
            height="4445px"
            alt="arrow right decoration"
            className="ac-milk-imgmob"
          />
        </div>
      </div>

      <img
        src="/img/2022/spiral.png"
        width="343px"
        height="282px"
        alt=""
        className="ac-spiral-img"
      />
      <img
        src="/img/2022/MaskGroup6.png"
        width="184px"
        height="157px"
        alt=""
        className="ac-spiral-imgmob"
      />
    </div>
  );
};
